import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Blogs from "./pages/Blogs";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import Services from "./pages/Services";
import BlogPost from "./pages/BlogPost";
import WebsiteDevelopment from "./pages/services/WebsiteDevelopment";
import AppDevelopment from "./pages/services/AppDevelopment";
import SoftwareAutomation from "./pages/services/SoftwareAutomation";
import ChatGPTIntegration from "./pages/services/ChatGPTIntegration";
import ResearchCollaboration from "./pages/services/ResearchCollaboration";

function App() {
	return (
		<div className="App">
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Layout />}>
						<Route index element={<Home />} />
						<Route path="services" element={<Services />} />
						<Route
							path="services/website-development"
							element={<WebsiteDevelopment />}
						/>
						<Route
							path="services/app-development"
							element={<AppDevelopment />}
						/>
						<Route
							path="services/software-automation"
							element={<SoftwareAutomation />}
						/>
						<Route
							path="services/chatgpt-integration"
							element={<ChatGPTIntegration />}
						/>
						<Route
							path="services/research-collaboration"
							element={<ResearchCollaboration />}
						/>
						<Route path="blogs" element={<Blogs />} />
						<Route path="blogs/:slug" element={<BlogPost />} />
						<Route path="contact" element={<Contact />} />
						<Route path="*" element={<NoPage />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;

import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const WebsiteDevelopment = () => {
	return (
		<Container>
			<Row className="my-5">
				<Col>
					<h1 className="text-center">
						Website Development with AI Integration
					</h1>
					<p className="lead text-center">
						In today’s fast-paced world, your website is more than
						just an online presence; it’s a dynamic reflection of
						your brand, a gateway to your services, and a tool that
						can elevate your business to new heights. That’s why we
						go beyond traditional web design. With AI at the core of
						our development process, we create smart, responsive
						websites that learn from user interactions, anticipate
						needs, and deliver personalized experiences that
						resonate with your audience.
					</p>
					<p>
						Imagine a website that evolves alongside your business,
						continuously optimizing itself to enhance user
						engagement, streamline operations, and drive
						conversions. Whether you’re a startup looking to make
						your mark or an established enterprise seeking to
						innovate, AierTeam is here to turn your vision into
						reality.
					</p>
				</Col>
			</Row>

			<Row className="my-5">
				<Col>
					<h2>Technology We Provide</h2>
				</Col>
			</Row>

			<Row className="my-4">
				<Col md={6}>
					<Card className="mb-4">
						<Card.Body>
							<Card.Title>
								1. Customized Website Design
							</Card.Title>
							<Card.Text>
								<strong>Tailored Solutions:</strong> We
								understand that every business is unique, which
								is why we offer fully customized website designs
								that reflect your brand identity and cater to
								your specific needs. Whether you’re looking for
								a minimalist design or a feature-rich platform,
								we’ll create a website that aligns with your
								vision.
							</Card.Text>
							<Card.Text>
								<strong>Responsive Design:</strong> Our websites
								are designed to be fully responsive, ensuring
								that they look great and function perfectly on
								all devices, from desktops to smartphones.
							</Card.Text>
							<Card.Text>
								<strong>AI-Driven Personalization:</strong> By
								integrating advanced AI, we create websites that
								adapt to each user’s behavior, offering
								personalized content and recommendations that
								keep visitors engaged and more likely to return.
								AI-powered chatbots integrated into the site
								enhance customer interactions by providing
								instant responses, guiding navigation, and
								offering tailored product suggestions.
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>

				<Col md={6}>
					<Card className="mb-4">
						<Card.Body>
							<Card.Title>2. WordPress Website</Card.Title>
							<Card.Text>
								<strong>Ease of Use:</strong> We specialize in
								WordPress, the world’s most popular content
								management system (CMS). WordPress offers a
								user-friendly interface, making it easy for you
								to manage and update your website without
								needing extensive technical knowledge.
							</Card.Text>
							<Card.Text>
								<strong>Extensive Customization:</strong> With
								thousands of themes and plugins available, we
								customize your WordPress site to meet your exact
								requirements, whether you need an e-commerce
								store, a portfolio, or a corporate site.
							</Card.Text>
							<Card.Text>
								<strong>SEO Optimization:</strong> Our WordPress
								websites are optimized for search engines right
								out of the box, helping you to improve your
								site’s visibility and ranking on Google and
								other search engines. We also integrate AI tools
								that continuously optimize your content for
								better SEO performance.
							</Card.Text>
							<Card.Text>
								<strong>Security and Maintenance:</strong> We
								ensure your WordPress site is secure and
								up-to-date with regular maintenance, security
								patches, and backups. Our AI-driven monitoring
								tools can predict potential vulnerabilities and
								address them proactively.
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default WebsiteDevelopment;

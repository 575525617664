import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./AIServices.css";

const AIServices = () => {
	return (
		<Container fluid className="services-container my-5">
			<Row>
				<Col>
					<h1 className="services-title text-center my-5">
						Artificial Intelligence Services for Business
					</h1>
					<div className="services-body text-center mb-5">
						As a seasoned AI service provider, we offer classic
						artificial intelligence services, data science, and
						machine learning for efficient AI solutions development
						and LLMs fine-tuning.
					</div>
				</Col>
			</Row>

			<Row>
				<Col
					md={6}
					className="mb-4 d-flex justify-content-end align-items-center"
				>
					<div className="services-text">
						<h3 className="services-text-title d-flex justify-content-start">
							{" "}
							AI Consulting &amp; Development
						</h3>
						<p className="services-text-body d-flex justify-content-start">
							Our artificial intelligence services company helps
							businesses analyze their business needs and validate
							project ideas, running smooth software development
							operations and minimizing risks. From the business
							case validation, project roadmap, and AI tool
							selection to development and post-go-live support,
							we’re here to help you build a tech-driven and
							high-growth business.
						</p>
					</div>
				</Col>
				<Col
					md={6}
					className="mb-4 d-flex justify-content-start align-items-center"
				>
					<div>
						<img
							src={`${process.env.PUBLIC_URL}/images/Services/AIConsulting.gif`}
							alt="Service AIConsulting Icon"
							className="services-image"
						/>
					</div>
				</Col>
			</Row>

			<Row>
				<Col
					md={6}
					className="mb-4 d-flex justify-content-end align-items-center"
				>
					<div className="d-flex justify-content-end">
						<img
							src={`${process.env.PUBLIC_URL}/images/Services/Generative.gif`}
							alt="Service GenerativeAI Icon"
							className="services-image"
						/>
					</div>
				</Col>
				<Col
					md={6}
					className="mb-4 d-flex justify-content-start align-items-center"
				>
					<div className="services-text">
						<h3 className="services-text-title">
							{" "}
							Generative AI Development
						</h3>
						<p className="services-text-body">
							We assist our clients in building customized
							Generative AI solutions for easier real-time
							customer experience services, strengthened security,
							and maximized efficiency. With 10+ years of
							experience in the industry, we develop
							state-of-the-art virtual assistants and chatbots,
							integrate ChatGPT and LLM-driven software solutions
							to level up our clients’ GenAI efforts.
						</p>
					</div>
				</Col>
			</Row>

			<Row>
				<Col
					md={6}
					className="mb-4 d-flex justify-content-end align-items-center"
				>
					<div className="services-text">
						<h3 className="services-text-title d-flex justify-content-start">
							{" "}
							Data Science &amp; Machine Learning
						</h3>
						<p className="services-text-body">
							We provide custom data science, artificial
							intelligence, and machine learning services. Let us
							help you make use of your data, translate your
							customer insights, and handle your analytics
							challenges by harnessing high-end technology. From
							data strategy and engineering, model training and
							development to maintenance, we will navigate your
							data journey.
						</p>
					</div>
				</Col>
				<Col
					md={6}
					className="mb-4 d-flex justify-content-start align-items-center"
				>
					<div>
						<img
							src={`${process.env.PUBLIC_URL}/images/Services/DAML.gif`}
							alt="Service Data and ML Icon"
							className="services-image"
						/>
					</div>
				</Col>
			</Row>

			<Row>
				<Col
					md={6}
					className="mb-4 d-flex justify-content-end align-items-center"
				>
					<div className="d-flex justify-content-end">
						<img
							src={`${process.env.PUBLIC_URL}/images/Services/Predictive.gif`}
							alt="Service Predictive Icon"
							className="services-image"
						/>
					</div>
				</Col>
				<Col
					md={6}
					className="mb-4 d-flex justify-content-start align-items-center"
				>
					<div className="services-text">
						<h3 className="services-text-title">
							{" "}
							NLP &amp; Predictive Analytics
						</h3>
						<p className="services-text-body">
							Our team of data scientists and NLP developers will
							assist you in creating tailored customer analytics
							and sentiment analysis software, chatbots and
							virtual assistants, recommendation systems, business
							analytics, and forecasting software. Let us help you
							get hold of your data and make more informed
							decisions with the help of the latest technologies.
						</p>
					</div>
				</Col>
			</Row>

			<Row>
				<Col
					md={6}
					className="mb-4 d-flex justify-content-end align-items-center"
				>
					<div className="services-text">
						<h3 className="services-text-title">Big Data Analytics</h3>
						<p className="services-text-body">
							Pass the task of data collection, analysis, and
							visualization on to a team of professionals. We’ll
							build data lakes and warehouses to gain global
							insights and improve business intelligence and
							efficiency. Build cloud solutions and integrate BI
							for improved data quality management and bolstered
							security of your system.
						</p>
					</div>
				</Col>
				<Col
					md={6}
					className="mb-4 d-flex justify-content-start align-items-center"
				>
					<div>
						<img
							src={`${process.env.PUBLIC_URL}/images/Services/BigData.gif`}
							alt="Service BigData Icon"
							className="services-image"
						/>
					</div>
				</Col>
			</Row>

			<Row>
				<Col
					md={6}
					className="mb-4 d-flex justify-content-end align-items-center"
				>
					<div className="d-flex justify-content-end">
						<img
							src={`${process.env.PUBLIC_URL}/images/Services/Custom.gif`}
							alt="Service Custom Icon"
							className="services-image"
						/>
					</div>
				</Col>
				<Col
					md={6}
					className="mb-4 d-flex justify-content-start align-items-center"
				>
					<div className="services-text">
						<h3 className="services-text-title">
							{" "}
							Custom Software Development
						</h3>
						<p className="services-text-body">
							Our artificial intelligence services company assists
							clients in tailored software development, guiding
							them through all development stages, from consulting
							to maintenance. Need to develop a product from
							scratch or improve the existing one with
							cutting-edge tech? We’re here to help with AI
							software development.
						</p>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default AIServices;

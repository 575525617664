import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Card, Row, Col } from "react-bootstrap";
import "./Blogs.css";

const Blogs = () => {
	const initialBlogs = [
		{
			slug: "applications-ai",
			title: "AI Agents: Examples, Functions, Applications, Future Trends",
			imgSrc: `${process.env.PUBLIC_URL}/blogs/applications-ai/applications-ai.png`,
			brief: "",
		},
		{
			slug: "business-ai",
			title: "15 Companies Using Generative AI for Business Efficiency",
			imgSrc: `${process.env.PUBLIC_URL}/blogs/business-ai/business-ai.png`,
			brief: "",
		},
		{
			slug: "capabilities-ai",
			title: "10 AI Capabilities for Business",
			imgSrc: `${process.env.PUBLIC_URL}/blogs/capabilities-ai/capabilities-ai.png`,
			brief: "",
		},
		{
			slug: "discovering-ai",
			title: "AI Exploration: Discovering AI Possibilities and Future",
			imgSrc: `${process.env.PUBLIC_URL}/blogs/discovering-ai/discovering-ai.png`,
			brief: "",
		},
		{
			slug: "enterprise-ai",
			title: "Enterprise AI Development Solutions: The Key to Business Transformation",
			imgSrc: `${process.env.PUBLIC_URL}/blogs/enterprise-ai/enterprise-ai.png`,
			brief: "",
		},
	];

	const [blogs, setBlogs] = useState(initialBlogs);

	useEffect(() => {
		const fetchBriefs = async () => {
			const updatedBlogs = await Promise.all(
				initialBlogs.map(async (blog) => {
					try {
						const response = await fetch(
							`/blogs/${blog.slug}/${blog.slug}.md`
						);
						if (!response.ok) {
							throw new Error("Network response was not ok");
						}
						const text = await response.text();
						return {
							...blog,
							brief: text.substring(1, 200) + "...",
						};
					} catch (error) {
						console.error(
							"Error fetching the markdown file:",
							error
						);
						return blog; // Return the original blog object in case of an error
					}
				})
			);
			setBlogs(updatedBlogs);
		};

		fetchBriefs();
	}, []);

	return (
		<Container className="blogs-container">
			<h1 className="my-4 text-center">Blog Articles</h1>
			<Row>
				{blogs.map((blog) => (
					<Col key={blog.slug} sm={12} md={6} lg={4} className="mb-4">
						<Card className="card-custom">
							<Card.Img
								variant="top"
								src={blog.imgSrc}
								alt={blog.title}
								className="card-img-custom"
							/>
							<Card.Body className="card-body-custom">
								<Card.Title
									as="h5"
									className="card-title-custom"
								>
									<Link
										to={`/blogs/${blog.slug}`}
										className="text-decoration-none"
									>
										{blog.title}
									</Link>
								</Card.Title>
								<Card.Text className="card-text-custom">
									{blog.brief}
								</Card.Text>
								<Link
									to={`/blogs/${blog.slug}`}
									className="btn btn-primary read-more-btn mt-auto"
								>
									Read More
								</Link>
							</Card.Body>
						</Card>
					</Col>
				))}
			</Row>
		</Container>
	);
};

export default Blogs;

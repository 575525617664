import React from "react";
import ContactForm from "../components/ContactForm/ContactForm";
const Contact = () => {
	return (
		<div>
			<ContactForm />
		</div>
	);
};

export default Contact;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { marked } from "marked";

const BlogPost = () => {
	const { slug } = useParams();
	const [content, setContent] = useState(null);

	useEffect(() => {
		const loadContent = async () => {
			try {
				const response = await fetch(
					`${process.env.PUBLIC_URL}/blogs/${slug}/${slug}.md`
				);
				if (!response.ok) {
					throw new Error("Network response was not ok");
				}
				const text = await response.text();
				setContent(text);
			} catch (error) {
				console.error("Error loading MDX file:", error);
				setContent("# Error loading content");
			}
		};

		loadContent();
	}, [slug]);

	return (
		<Container className="blog-post my-4">
			{content ? (
				<div dangerouslySetInnerHTML={{ __html: marked(content) }} />
			) : (
				<p>Loading...</p>
			)}
		</Container>
	);
};

export default BlogPost;

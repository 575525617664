import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const SoftwareAutomation = () => {
	return (
		<Container>
			<Row className="my-5">
				<Col>
					<h1 className="text-center">
						Software Automation Expertise
					</h1>
					<p className="lead text-center">
						When it comes to software development, testing is
						crucial—but do you need it all? We recognize that each
						project has its own requirements, which is why we
						customize the perfect testing solution tailored to your
						specific needs. Whether you need comprehensive testing
						across all phases or targeted testing in key areas,
						we’ve got you covered.
					</p>
				</Col>
			</Row>

			<Row className="my-5">
				<Col>
					<h2 className="text-center">Technology We Provide</h2>
				</Col>
			</Row>

			<Row className="my-4">
				<Col md={6}>
					<Card className="mb-4">
						<Card.Body>
							<Card.Title>1. Unit Testing</Card.Title>
							<Card.Text>
								<strong>Ensuring Code Quality:</strong> We
								strongly recommend that developers write unit
								tests as part of their coding process. Unit
								testing focuses on verifying the smallest parts
								of your code—individual functions or methods. By
								catching bugs early in the development process,
								unit tests help ensure that your codebase is
								solid from the ground up, reducing the risk of
								issues later on.
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>

				<Col md={6}>
					<Card className="mb-4">
						<Card.Body>
							<Card.Title>2. Integration Testing</Card.Title>
							<Card.Text>
								<strong>Seamless Component Interaction:</strong>{" "}
								After individual units are tested, we move on to
								integration testing. This phase ensures that
								different modules and components of your
								application work together as expected. We
								identify and resolve any issues arising from the
								interaction between components, ensuring smooth
								and seamless functionality.
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			<Row className="my-4">
				<Col md={6}>
					<Card className="mb-4">
						<Card.Body>
							<Card.Title>
								3. User Acceptance Testing (UAT)
							</Card.Title>
							<Card.Text>
								<strong>
									Final Validation with Real Users:
								</strong>{" "}
								The final phase, user acceptance testing,
								involves testing the software in real-world
								scenarios to ensure it meets the needs and
								expectations of the end-users. We work closely
								with your team to simulate actual user
								conditions, providing the final assurance that
								your software is ready for deployment.
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>

				<Col md={6}>
					<Card className="mb-4">
						<Card.Body>
							<Card.Title>
								4. Continuous Integration and Continuous
								Deployment (CI/CD) Integration
							</Card.Title>
							<Card.Text>
								<strong>
									Streamlining Development and Testing:
								</strong>{" "}
								To further enhance the efficiency of your
								development process, we integrate Continuous
								Integration and Continuous Deployment (CI/CD)
								pipelines. CI/CD ensures that code changes are
								automatically tested and deployed, reducing the
								time between writing code and delivering it to
								production. This automation helps catch issues
								early, maintain code quality, and accelerate
								your release cycles.
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			<Row className="my-4">
				<Col md={12}>
					<Card className="mb-4">
						<Card.Body>
							<Card.Title>5. Automated Testing</Card.Title>
							<Card.Text>
								<strong>Efficiency and Consistency:</strong>{" "}
								Throughout these phases, we integrate automated
								testing to streamline the process, ensuring
								faster and more consistent results. Our
								automation tools cover various testing needs,
								including functional, performance, and
								regression testing, helping to accelerate
								development cycles and maintain high quality.
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default SoftwareAutomation;

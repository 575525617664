import React from "react";

const NoPage = () => {
	return (
		<div>
			<p>404 Not Found</p>
		</div>
	);
};

export default NoPage;

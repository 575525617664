import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const AppDevelopment = () => {
	return (
		<Container>
			<Row className="my-5">
				<Col>
					<h1 className="text-center">
						App Development with AI Integration
					</h1>
					<p className="lead text-center">
						In today’s digital landscape, creating an app is not
						just about functionality; it’s about providing
						intelligent, seamless experiences that keep users
						engaged and satisfied. At AierTeam, we specialize in
						developing cutting-edge apps that integrate advanced AI
						technologies, delivering smarter, more responsive
						solutions tailored to your business needs.
					</p>
				</Col>
			</Row>

			<Row className="my-5">
				<Col>
					<h2 className="text-center">Highlights</h2>
				</Col>
			</Row>

			<Row className="my-4">
				<Col md={6}>
					<Card className="mb-4">
						<Card.Body>
							<Card.Title>Tailored AI Models</Card.Title>
							<Card.Text>
								We develop custom AI models that fit your
								specific app requirements, whether it’s for
								personalized recommendations, predictive
								analytics, or intelligent automation. Our
								expertise spans various AI technologies,
								including machine learning, natural language
								processing, and computer vision.
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>

				<Col md={6}>
					<Card className="mb-4">
						<Card.Body>
							<Card.Title>
								Voice and Chatbot Integration
							</Card.Title>
							<Card.Text>
								Our expertise includes integrating AI-powered
								voice assistants and chatbots into your app,
								enabling natural, conversational interactions.
								Whether it’s for customer support, navigation,
								or task automation, these features make your app
								more intuitive and user-friendly.
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			<Row className="my-4">
				<Col md={6}>
					<Card className="mb-4">
						<Card.Body>
							<Card.Title>Multi-Platform Expertise</Card.Title>
							<Card.Text>
								We develop AI-integrated apps for multiple
								platforms, including iOS, Android, and web. Our
								cross-platform expertise ensures that your app
								delivers a consistent and high-quality
								experience across all devices.
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>

				<Col md={6}>
					<Card className="mb-4">
						<Card.Body>
							<Card.Title>Scalability and Performance</Card.Title>
							<Card.Text>
								Our apps are built to scale, handling increasing
								user demands without compromising performance.
								We use the latest tools and frameworks to ensure
								that your app remains fast, secure, and
								reliable.
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			<Row className="my-4">
				<Col md={6}>
					<Card className="mb-4">
						<Card.Body>
							<Card.Title>Automated Testing with AI</Card.Title>
							<Card.Text>
								We use AI-driven automated testing tools to
								ensure your app is bug-free and performs
								optimally across all scenarios. Our rigorous
								testing process includes unit testing,
								integration testing, and performance testing,
								providing you with a high-quality product ready
								for deployment.
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>

				<Col md={6}>
					<Card className="mb-4">
						<Card.Body>
							<Card.Title>
								Continuous Integration and Deployment (CI/CD)
							</Card.Title>
							<Card.Text>
								We implement CI/CD pipelines to automate the app
								development lifecycle, ensuring faster releases
								and continuous improvement. This approach
								reduces time to market and allows for regular
								updates and enhancements based on user feedback
								and data.
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default AppDevelopment;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CountUp from "react-countup";
import "bootstrap/dist/css/bootstrap.min.css";
import "./BeneficialResults.css";

const beneficialResults = [
	{
		title: "Improved Efficiency",
		description:
			"Our solutions streamline processes and improve overall efficiency.",
		value: 85, // Example percentage
	},
	{
		title: "Cost Savings",
		description:
			"Implementing AI and ML reduces operational costs significantly.",
		value: 75, // Example percentage
	},
	{
		title: "Enhanced Reliability",
		description:
			"Advanced algorithms ensure high accuracy in data analysis and predictions.",
		value: 90, // Example percentage
	},
	{
		title: "Tailored Solutions",
		description:
			"Every project is unique, that's why we provide tailored solutions to meet the specific needs of our clients.",
		value: 100, // Example percentage
	},
];

const BeneficialResults = () => {
	return (
		<Container className="beneficial-container my-5">
			<h1 className="beneficial-title text-center mb-5">
				Beneficial Results
			</h1>
			<Row className="justify-content-center">
				{beneficialResults.map((result, index) => (
					<Col md={3} className="mb-5 text-center" key={index}>
						<div className="beneficial-result mb-4">
							<CountUp
								start={0}
								end={result.value}
								duration={2.5}
								suffix="%"
								className="countup beneficial-card-title"
							/>
							<h3 className="beneficial-card-title mt-3">
								{result.title}
							</h3>
							<p className="beneficial-card-text">
								{result.description}
							</p>
						</div>
					</Col>
				))}
			</Row>
		</Container>
	);
};

export default BeneficialResults;

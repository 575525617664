import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Header.css";

const Header = () => {
	return (
		<div className="navbar-container">
			<Navbar variant="dark" expand="lg" className="custom-navbar">
				<Navbar.Brand as={Link} to="/">
					<h4 className="permanent-marker-regular">
						AierTeam Consulting
					</h4>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="ml-auto">
						<Nav.Link as={Link} to="/">
							Home
						</Nav.Link>
						<div className="nav-item dropdown services-dropdown">
							<Nav.Link
								as={Link}
								to="/services"
								className="dropdown-toggle"
							>
								Services
							</Nav.Link>
							<div className="dropdown-menu">
								<Nav.Link
									as={Link}
									to="/services/website-development"
									className="dropdown-item"
								>
									Website Development
								</Nav.Link>
								<Nav.Link
									as={Link}
									to="/services/app-development"
									className="dropdown-item"
								>
									App Development
								</Nav.Link>
								<Nav.Link
									as={Link}
									to="/services/software-automation"
									className="dropdown-item"
								>
									Software Automation
								</Nav.Link>
								<Nav.Link
									as={Link}
									to="/services/chatgpt-integration"
									className="dropdown-item"
								>
									ChatGPT Integration
								</Nav.Link>
								<Nav.Link
									as={Link}
									to="/services/research-collaboration"
									className="dropdown-item"
								>
									Research Collaboration
								</Nav.Link>
							</div>
						</div>
						<Nav.Link as={Link} to="/blogs">
							Blogs
						</Nav.Link>
						<Nav.Link as={Link} to="/contact">
							Contact
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</div>
	);
};

export default Header;

import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./HowCanWeHelp.css";

const howCanWeHelp = [
	{
		imgSrc: `${process.env.PUBLIC_URL}/images/HowCanWeHelp/Consulting.svg`,
		title: "AI/ML Consulting",
		description:
			"Have a project in mind and need help with implementation? We are here to help you and share our knowledge to help you avoid all unnecessary pitfalls.",
	},
	{
		imgSrc: `${process.env.PUBLIC_URL}/images/HowCanWeHelp/POC.svg`,
		title: "POC of AI-based Solution",
		description:
			"POC is an essential step before adopting any AI solution. If you have a project idea, We help you to build a POC to validate the feasibility of the solution.",
	},
	{
		imgSrc: `${process.env.PUBLIC_URL}/images/HowCanWeHelp/MVP.svg`,
		title: "MVP of AI-based Product",
		description:
			"Need a breakthrough AI product? We are ready to help you build an MVP version of your product to test the market and get feedback from early users.",
	},
	{
		imgSrc: `${process.env.PUBLIC_URL}/images/HowCanWeHelp/Software.svg`,
		title: "AI Software Development",
		description:
			"If you need to develop any innovative application from scratch with AI injected, we have expertises in Web, Mobile, and Desktop application development to assist you.",
	},
	{
		imgSrc: `${process.env.PUBLIC_URL}/images/HowCanWeHelp/Custom.svg`,
		title: "Custom Model Development",
		description:
			"We can build and train custom models for you business needs, or fine-tune existing models to improve performance.",
	},
	{
		imgSrc: `${process.env.PUBLIC_URL}/images/HowCanWeHelp/LLM.svg`,
		title: "LLM Engineering",
		description:
			"Need help with Large Language Models (LLM) like GPT-4o? We can help you choose the model that fits your business best with the prompting and tuning services.",
	},
];

const HowCanWeHelp = () => {
	return (
		<Container className="help-container my-5">
			<h1 className="help-title text-center mb-5">How Can We Help You</h1>
			<Row className="justify-content-center">
				{howCanWeHelp.map((howCanWeHelp, index) => (
					<Col md={4} className="mb-5" key={index}>
						<Card className="help-card mb-4 h-100">
							<Card.Body className="d-flex flex-column">
								<Row>
									<Col className="d-flex align-items-center mb-3">
										<Card.Img
											src={howCanWeHelp.imgSrc}
											alt={howCanWeHelp.title}
											className="help-card-image me-3"
										/>
										<Card.Title className="help-card-title">
											{howCanWeHelp.title}
										</Card.Title>
									</Col>
								</Row>
								<div>
									<Card.Text className="help-card-text">
										{howCanWeHelp.description}
									</Card.Text>
								</div>
							</Card.Body>
						</Card>
					</Col>
				))}
			</Row>
		</Container>
	);
};

export default HowCanWeHelp;

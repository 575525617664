import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./ContactForm.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faUser,
	faEnvelope,
	faPhone,
	faSchool,
	faComment,
	faDollarSign,
} from "@fortawesome/free-solid-svg-icons";

const ContactForm = () => {
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		phone: "",
		schoolCompany: "",
		message: "",
		budget: "",
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const response = await fetch("/send-email", {
			method: "POST",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
			body: new URLSearchParams(formData),
		});
		if (response.ok) {
			alert("Message sent successfully");
		} else {
			alert("Error sending message");
		}
	};

	return (
		<Container className="contact-form-container">
			<Row className="justify-content-center">
				<Col md={8}>
					<h1 className="contact-title text-center">Contact Us</h1>
					<Form id="contact-form" onSubmit={handleSubmit}>
						<Form.Group controlId="formName" className="mb-2">
							<Form.Label>
								<FontAwesomeIcon icon={faUser} /> Name
							</Form.Label>
							<Form.Control
								type="text"
								name="name"
								value={formData.name}
								onChange={handleChange}
							/>
						</Form.Group>

						<Form.Group controlId="formEmail" className="mb-2">
							<Form.Label>
								<FontAwesomeIcon icon={faEnvelope} /> Email
							</Form.Label>
							<Form.Control
								type="email"
								name="email"
								value={formData.email}
								onChange={handleChange}
							/>
						</Form.Group>

						<Form.Group controlId="formPhone" className="mb-2">
							<Form.Label>
								<FontAwesomeIcon icon={faPhone} /> Phone
							</Form.Label>
							<Form.Control
								type="tel"
								name="phone"
								value={formData.phone}
								onChange={handleChange}
							/>
						</Form.Group>

						<Form.Group
							controlId="formSchoolCompany"
							className="mb-2"
						>
							<Form.Label>
								<FontAwesomeIcon icon={faSchool} /> Orgnazation
								Name
							</Form.Label>
							<Form.Control
								type="text"
								name="schoolCompany"
								value={formData.schoolCompany}
								onChange={handleChange}
							/>
						</Form.Group>

						<Form.Group controlId="formMessage" className="mb-2">
							<Form.Label>
								<FontAwesomeIcon icon={faComment} /> What
								solutions you are looking for?
							</Form.Label>
							<Form.Control
								as="textarea"
								name="message"
								rows={4}
								value={formData.message}
								onChange={handleChange}
								required
							/>
						</Form.Group>
						<Form.Group controlId="formBudget" className="mb-2">
							<Form.Label>
								<FontAwesomeIcon icon={faDollarSign} /> Budget
								(USD)
							</Form.Label>
							<Form.Control
								type="number"
								name="budget"
								value={formData.budget}
								onChange={handleChange}
								step="5000" // Set the step to 5000
							/>
						</Form.Group>
						<Button
							variant="primary"
							type="submit"
							className="mt-2"
						>
							Send Message
						</Button>
					</Form>
				</Col>
			</Row>
		</Container>
	);
};

export default ContactForm;

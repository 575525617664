import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Expertise.css";

const expertise = [
	{
		imgSrc: `${process.env.PUBLIC_URL}/images/Expertise/AIConsulting.svg`,
		title: "AI Consulting",
		description:
			"We provide expert advice and strategies to help businesses leverage AI technologies.",
	},
	{
		imgSrc: `${process.env.PUBLIC_URL}/images/Expertise/MachineLearning.svg`,
		title: "Machine Learning Solutions",
		description:
			"Customized machine learning solutions to solve complex business problems.",
	},
	{
		imgSrc: `${process.env.PUBLIC_URL}/images/Expertise/GenerativeAI.svg`,
		title: "Generative AI",
		description:
			"Developing advanced generative AI models for creative and innovative solutions.",
	},
	{
		imgSrc: `${process.env.PUBLIC_URL}/images/Expertise/NLPSolutions.svg`,
		title: "NLP Solutions",
		description:
			"Implementing natural language processing to improve communication and data analysis.",
	},
	{
		imgSrc: `${process.env.PUBLIC_URL}/images/Expertise/DataEngineering.svg`,
		title: "Data Engineering Services",
		description:
			"Building robust data pipelines and infrastructures to support AI and ML initiatives.",
	},
];

const Expertise = () => {
	return (
		<Container fluid className="expertise-container my-5">
			<Row>
				<Col>
					<h1 className="expertise-title text-center mb-5">
						Our Expertise
					</h1>
				</Col>
			</Row>

			<Row className="d-flex justify-content-around align-items-center">
				<Col
					md={6}
					className="mb-4 d-flex justify-content-center align-items-center"
				>
					<img
						src={`${process.env.PUBLIC_URL}/images/Expertise/Expertise.svg`}
						alt="Expertise Icon"
						className="expertise-image"
					/>
				</Col>
				<Col
					md={6}
					className="mb-4 d-flex justify-content-center align-items-center"
				>
					<div className="expertise-cards">
						{expertise.map((expertise, index) => (
							<Card className="expertise-card mb-4" key={index}>
								<Card.Body className="d-flex align-items-center">
									<Card.Img
										src={expertise.imgSrc}
										alt={expertise.title}
										className="expertise-card-image me-3"
									/>
									<div>
										<Card.Title className="expertise-card-title">
											{expertise.title}
										</Card.Title>
										<Card.Text className="expertise-card-text">
											{expertise.description}
										</Card.Text>
									</div>
								</Card.Body>
							</Card>
						))}
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default Expertise;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Footer.css";

const Footer = () => {
	return (
		<footer className="footer-container mt-5">
			<Container>
				<Row>
					<Col md={4}>
						<h5>About Us</h5>
						<p>
							We provide expert consulting services in AI and
							software engineering.
						</p>
					</Col>
					<Col md={4}>
						<h5>Contact Us</h5>
						<ul className="list-unstyled">
							<li>Email: info@aierteam.com</li>
							<li>Phone: 314-960-1558</li>
						</ul>
					</Col>
					<Col md={4}>
						<h5>Follow Us</h5>
						<ul className="list-unstyled">
							<li>
								<a href="https://www.linkedin.com/company/anrib">
									LinkedIn
								</a>
							</li>
						</ul>
					</Col>
				</Row>
				<Row>
					<Col className="text-center mt-3">
						<p>
							&copy; {new Date().getFullYear()} AierTeam
							Consulting. All rights reserved.
						</p>
					</Col>
				</Row>
			</Container>
		</footer>
	);
};

export default Footer;

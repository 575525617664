import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const ResearchCollaboration = () => {
	return (
		<Container>
			<Row className="my-5">
				<Col>
					<h1 className="text-center">
						Collaborating with Research Labs
					</h1>
					<p className="lead text-center">
						At AierTeam, we are passionate about pushing the
						boundaries of technology and innovation. That’s why
						we’re excited to collaborate with research labs,
						providing specialized services in cutting-edge fields
						such as computer vision, biology analysis, and AI
						pipeline setup. Our expertise in AI and advanced
						technologies positions us as a valuable partner for
						research institutions looking to accelerate their
						discoveries and innovations.
					</p>
				</Col>
			</Row>

			<Row className="my-5">
				<Col>
					<h2 className="text-center">Our Expertise</h2>
				</Col>
			</Row>

			<Row className="my-4">
				<Col md={6}>
					<Card className="mb-4">
						<Card.Body>
							<Card.Title>
								1. Computer Vision Solutions
							</Card.Title>
							<Card.Text>
								<strong>
									Advanced Image and Video Analysis:
								</strong>{" "}
								We offer state-of-the-art computer vision
								solutions that enable research labs to process
								and analyze large datasets of images and videos.
								From object detection and tracking to image
								classification and segmentation, our solutions
								are designed to meet the complex demands of
								scientific research.
							</Card.Text>
							<Card.Text>
								<strong>Custom Model Development:</strong> We
								develop custom computer vision models tailored
								to the specific needs of your research projects.
								Whether it’s for medical imaging, environmental
								monitoring, or other specialized applications,
								we ensure that our models are accurate,
								efficient, and scalable.
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>

				<Col md={6}>
					<Card className="mb-4">
						<Card.Body>
							<Card.Title>2. Biology Data Analysis</Card.Title>
							<Card.Text>
								<strong>AI-Powered Biological Analysis:</strong>{" "}
								Our services include developing AI tools that
								assist in the analysis of biological data, such
								as genomic sequences, protein structures, and
								cellular images. We help research labs automate
								complex analysis tasks, enabling faster and more
								accurate results.
							</Card.Text>
							<Card.Text>
								<strong>
									Integration with Laboratory Equipment:
								</strong>{" "}
								We work closely with research labs to integrate
								AI-powered analysis tools with existing
								laboratory equipment and software, ensuring a
								seamless workflow that enhances productivity and
								precision in biological research.
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			<Row className="my-4">
				<Col md={12}>
					<Card className="mb-4">
						<Card.Body>
							<Card.Title>3. AI Pipeline Setup</Card.Title>
							<Card.Text>
								<strong>End-to-End AI Solutions:</strong> We
								provide comprehensive AI pipeline setup
								services, from data collection and preprocessing
								to model training and deployment. Our end-to-end
								solutions ensure that research labs can
								efficiently manage their AI workflows, reducing
								the time and effort required to turn raw data
								into actionable insights.
							</Card.Text>
							<Card.Text>
								<strong>Scalable Infrastructure:</strong> We
								design and implement scalable AI pipelines that
								can handle large datasets and complex
								computations, making it easier for research labs
								to expand their research scope and capabilities.
								Our solutions are designed for flexibility,
								allowing for easy integration with existing
								systems and future expansion.
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default ResearchCollaboration;

import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Certificates.css";

const certificates = [
	{
		text: "AWS Certified Cloud Practitioner",
		imgSrc: `${process.env.PUBLIC_URL}/images/certificates/aws-certified-cloud-practitioner.png`,
	},
	{
		text: "AWS Data Analytics",
		imgSrc: `${process.env.PUBLIC_URL}/images/certificates/aws-data-analytics.png`,
	},
	{
		text: "AWS Database Specialty",
		imgSrc: `${process.env.PUBLIC_URL}/images/certificates/aws-database-specialty.png`,
	},
	{
		text: "AWS Developer Associate",
		imgSrc: `${process.env.PUBLIC_URL}/images/certificates/aws-developer-associate.png`,
	},
	{
		text: "AWS Machine Learning Specialty",
		imgSrc: `${process.env.PUBLIC_URL}/images/certificates/aws-machine-learning-specialty.png`,
	},
	{
		text: "AWS Solutions Architect Associate",
		imgSrc: `${process.env.PUBLIC_URL}/images/certificates/aws-solutions-architect-associate.png`,
	},
	{
		text: "AWS Solutions Architect Professional",
		imgSrc: `${process.env.PUBLIC_URL}/images/certificates/aws-solutions-architect-professional.png`,
	},
	{
		text: "Azure Administrator Associate",
		imgSrc: `${process.env.PUBLIC_URL}/images/certificates/azure-administrator-associate.png`,
	},
	{
		text: "Azure AI Engineer Associate",
		imgSrc: `${process.env.PUBLIC_URL}/images/certificates/azure-ai-engineer-associate.png`,
	},
	{
		text: "Azure Data Engineer Associate",
		imgSrc: `${process.env.PUBLIC_URL}/images/certificates/azure-data-engineer-associate.png`,
	},
	// {
	// 	text: "Confluent Apache Kafka",
	// 	imgSrc: `${process.env.PUBLIC_URL}/images/certificates/confluent-apache-kafka.png`,
	// },
	// {
	// 	text: "Databricks Spark",
	// 	imgSrc: `${process.env.PUBLIC_URL}/images/certificates/databricks-spark.png`,
	// },
	// {
	// 	text: "IBM Data and AI",
	// 	imgSrc: `${process.env.PUBLIC_URL}/images/certificates/ibm-data-and-ai.png`,
	// },
	{
		text: "Microsoft Certified Azure Fundamentals",
		imgSrc: `${process.env.PUBLIC_URL}/images/certificates/microsoft-certified-azure-fundamentals.png`,
	},
	{
		text: "Microsoft Certified Expert",
		imgSrc: `${process.env.PUBLIC_URL}/images/certificates/microsoft-certified-expert.png`,
	},
	{
		text: "Power BI Data Analyst",
		imgSrc: `${process.env.PUBLIC_URL}/images/certificates/power-bi-data-analyst.png`,
	},
];

const Certificates = () => {
	return (
		<Container className="certificates-container my-5">
			<h1 className="certificates-title text-center mb-5">
				Certificates
			</h1>
			<Row className="justify-content-center align-items-center">
				{certificates.map((certificate, index) => (
					<Col
						xs={6}
						sm={4}
						md={3}
						lg={2}
						className="mb-4"
						key={index}
					>
						<Card className="certificates-card">
							<Card.Body className="certificates-card-body justify-content-center">
								<Card.Img
									variant="top"
									src={certificate.imgSrc}
									alt={certificate.text}
									className="certificates-card-image"
								/>
								{/* <Card.Text className="certificates-card-text">
									{certificate.text}
								</Card.Text> */}
							</Card.Body>
						</Card>
					</Col>
				))}
			</Row>
		</Container>
	);
};

export default Certificates;

import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./About.css";

const About = () => {
	return (
		<div>
			<Container
				fluid
				className="about-container d-flex flex-column justify-content-center align-items-center"
			>
				<Row className="d-flex justify-content-center align-items-center mb-4">
					<Col xs="auto">
						<Image
							className="custom-image-size "
							src="/images/AierTeam-white.png"
							alt="AierTeam Logo"
							fluid
						/>
					</Col>
				</Row>
				<Row className="d-flex justify-content-center align-items-center">
					<Col xs="auto">
						<p className="about-text text-center mb-7">
							We care about small businesses and we want to help
							you grow with AI.
						</p>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default About;

import React from "react";
import AIServices from "../components/Services/AIServices/AIServices";
import WhyAIServices from "../components/Services/WhyAIServices/WhyAIServices";

const Services = () => {
	return (
		<div>
			<AIServices />
			{/* <WhyAIServices /> */}
		</div>
	);
};

export default Services;

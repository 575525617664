import React from "react";
import Expertise from "../components/Home/Expertise/Expertise";
import About from "../components/Home/About/About";
import Contact from "./Contact";
import BeneficialResults from "../components/Home/BeneficialResults/BeneficialResults";
import HowCanWeHelp from "../components/Home/HowCanWeHelp/HowCanWeHelp";
import Certificates from "../components/Home/Certificates/Certificates";

const Home = () => {
	return (
		<div>
			<About />
			<Expertise />
			<BeneficialResults />
			<HowCanWeHelp />
			<Certificates />
			<Contact />
		</div>
	);
};

export default Home;
